import React from "react"
import styled from "styled-components"

import { COLORS, SIZES, FONTS } from "src/helpers/constants"

export const InputContainer = styled.div<{ $multiline?: boolean }>`
  background-color: ${COLORS.white};
  padding: 5px;
  display: ${({ $multiline }) => ($multiline ? "block" : "flex")};
  margin-bottom: 10px;
`
export const Label = styled.label`
  font-family: ${FONTS.title};
  font-size: ${SIZES.label}px;
  text-transform: uppercase;
  margin: 2px 7px 0 3px;
  white-space: nowrap;
`
export const Input = styled.input`
  border: none;
  flex: 1;
  width: 100%;
  padding: 3px;
  line-height: 18px;
`
const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 5px 0;
  resize: none;
  border: none;
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string
  error?: string
  disclaimer?: string
  multiline?: boolean
}

const TextField: React.FC<Props> = ({ label, value, onChange, name, type, multiline, ...props }) => (
  <InputContainer $multiline={multiline}>
    <Label htmlFor={name}>{label} :</Label>
    {multiline ? (
      <TextArea id={name} name={name} value={value} onChange={onChange} {...props} />
    ) : (
      <Input id={name} name={name} value={value} onChange={onChange} type={type || "text"} {...props} />
    )}
  </InputContainer>
)

export default TextField
