import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { COLORS, FONTS, SIZES } from "src/helpers/constants"
import ArrowIcon from "src/svg/arrow.svg"

const buttonStyle = css`
  margin: 20px auto 40px;
  font-family: ${FONTS.title};
  font-size: ${SIZES.label}px;
  background-color: ${COLORS.white};
  padding: 4px 20px;
  svg {
    vertical-align: -7px;
    margin: 0 -7px 0 2px;
  }
`

const Btn = styled.button`
  display: block;
  ${buttonStyle}
`
const Lnk = styled(Link)`
  display: inline-block;
  ${buttonStyle}
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
}

const Button: React.FC<Props> = ({ loading, children, ...props }) => (
  <Btn disabled={loading} {...props}>
    {loading ? (
      "Veuillez patienter…"
    ) : (
      <>
        {children} <ArrowIcon />
      </>
    )}
  </Btn>
)

export default Button

export const LinkButton: React.FC<LinkProps> = ({ children, ...props }) => (
  <Lnk {...props}>
    {children} <ArrowIcon />
  </Lnk>
)
