import HttpError from "standard-http-error"
import Bugsnag from "@bugsnag/js"
import { StripeError } from "@stripe/stripe-js"

import api, { Payload, Query } from "src/helpers/api"

const request = async <T>(method: string, name: string, params?: Payload | Query): Promise<T> =>
  api.request<T>(method, `/.netlify/functions/${name}`, params)

export default {
  get: <T = any>(name: string, params?: Query): Promise<T> => request<T>("GET", name, params),
  post: <T = any>(name: string, params: Payload): Promise<T> => request<T>("POST", name, params),
  put: <T = any>(name: string, params: Payload): Promise<T> => request<T>("PUT", name, params),
  delete: <T = any>(name: string, params: Payload): Promise<T> => request<T>("DELETE", name, params),
}

export const handleError = (error: Error | StripeError): void => {
  if (!(error instanceof HttpError) && "name" in error) {
    Bugsnag.notify(error)
  }
  alert("Erreur : " + error.message)
}
