import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { loadStripe, RedirectToCheckoutServerOptions } from "@stripe/stripe-js"
import styled from "styled-components"

import Layout from "src/layout"
import Menu from "src/components/Menu"
import Column from "src/layout/column"
import TextField from "src/components/TextField"
import Button from "src/components/Button"
import functions, { handleError } from "src/helpers/functions"
import { SIZES, EMAIL_ADDRESS } from "src/helpers/constants"

const Row = styled.div`
  display: flex;
  @media (max-width: 499px) {
    flex-wrap: wrap;
    > div {
      flex: none;
      width: 100%;
    }
  }
  @media (min-width: 500px) {
    > :first-child {
      margin-right: 10px;
      flex: 2;
    }
    > :last-child {
      flex: 3;
    }
  }
`
const Disclaimer = styled.div`
  font-size: ${SIZES.small}px;
  text-align: center;
  @media (max-width: 500px) {
    br {
      display: none;
    }
  }
`

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

interface MyState {
  items: OrderItem[]
  sale: Sale
}

const OrderPage: GatsbyPage<null, null, MyState> = ({ location }) => {
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    phone: "",
    message: "",
    zip: "",
    city: "",
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!location.state?.items) {
      navigate("/produits/")
    }
  }, [location.state?.items])

  const handleChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [currentTarget.name]: currentTarget.value.substr(0, 500),
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)

    const { items, sale } = location.state

    const order: Order = {
      sale,
      items,
      infos: {
        firstname: state.firstname,
        lastname: state.lastname,
        email: state.email,
        phone: state.phone,
        address: `${state.address} ${state.zip} ${state.city}`,
        message: state.message,
      },
    }

    Promise.all([stripePromise, functions.post<RedirectToCheckoutServerOptions>("session", order)])
      .then(([stripe, session]) => stripe.redirectToCheckout(session))
      .catch(handleError)
      .then(() => {
        setLoading(false)
      })
  }

  return (
    <Layout title="Commande" noindex>
      <Menu />
      <Column as="form" onSubmit={handleSubmit}>
        <TextField name="lastname" label="Nom" value={state.lastname} onChange={handleChange} required />
        <TextField name="firstname" label="Prénom" value={state.firstname} onChange={handleChange} required />
        <TextField name="address" label="Adresse" value={state.address} onChange={handleChange} required />
        <Row>
          <TextField name="zip" label="Code postal" value={state.zip} onChange={handleChange} required />
          <TextField name="city" label="Ville" value={state.city} onChange={handleChange} required />
        </Row>
        <TextField name="email" label="Courriel" value={state.email} onChange={handleChange} type="email" required />
        <TextField name="phone" label="Téléphone" value={state.phone} onChange={handleChange} required />
        <TextField
          name="message"
          label="Je laisse un message"
          value={state.message}
          onChange={handleChange}
          multiline
        />
        <Disclaimer>
          Ces informations sont collectées dans l’unique but de faciliter l’organisation de votre retrait. <br />
          Aucune utilisation commerciale n’en sera faite.
        </Disclaimer>
        <Button type="submit" loading={loading}>
          Paiement
        </Button>
        <Disclaimer>
          Si vous souhaitez payer par chèque, envoyez-nous votre commande :
          <br />
          <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
        </Disclaimer>
      </Column>
    </Layout>
  )
}

export default OrderPage
