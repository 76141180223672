import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { COLORS, FONTS, SIZES } from "src/helpers/constants"

const Nav = styled.nav`
  position: fixed;
  z-index: 1;
  top: 50px;
  right: 0px;
`
const NavLink = styled(Link)`
  display: block;
  background-color: ${COLORS.transparentWhite};
  font-family: ${FONTS.title};
  font-size: ${SIZES.menu}px;
  margin-bottom: 8px;
  padding: 3px 35px 0px 5px;
  text-decoration: none;
`

const Menu: React.FC = () => (
  <Nav>
    <NavLink to="/produits/">&gt; commande</NavLink>
    <NavLink to="/contact/">&gt; contact</NavLink>
  </Nav>
)

export default Menu
